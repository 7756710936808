<!-- 订单管理-开票申请 -->

<template>
  <div class="order-prestore main-cnt">
    <div class="content">
      <common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :extraParame="{req_type:'3'}"
        :apiName="Order.getChargingOrderLists" :filters="filters" :columns="tableColumns"
        @orderQuantity="orderQuantity">
        <template #download="{ row }">
          <template v-if="row.mn_status == '2' && authData.indexOf('n_sbTP7qolXv5f1JpptOU4SpDVDcsk') != -1">
            <el-button class="theme-font-btn" @click="onDownloadBtn(row)">下载</el-button>
          </template>
        </template>

        <template #upload="{ row }">
          <template v-if="!(row.mn_status == '2') && authData.indexOf('n_4mi71DqUuROesG1IyvrP0TEt5ILw') != -1">
            <el-button class="theme-font-btn" @click="onUploadAnnexBtn(row)">上传附件</el-button>
          </template>
        </template>

        <template #email_process_status="{row}">
          <div class="flex">
            <div :class="row.email_process_status == '失败' ?'text-red' :''">{{ row.email_process_status }}</div>
            <el-button class="theme-font-btn email-status"
              v-if="row.email_process_level == '2' && authData.indexOf('n_dfyg7HcIjNn4LVEoWn21YAOHu8tW') != -1"
              @click="onResendBtn(row)">重新发送</el-button>
          </div>
        </template>
      </common-table>
    </div>

    <!-- 订单详情 -->
    <w-dialog ref="detailsDialog" class="add-dialog" title="订单详情" width="60%" btnWidth="140px" top="20vh"
      :hideFooter="true">
      <el-table :data="tableData" show-summary style="width: 100%" height="500">
        <el-table-column prop="or_no" label="订单编号" show-overflow-tooltip width="210" />
        <el-table-column prop="or_money" label="订单金额" show-overflow-tooltip />
        <el-table-column prop="orw_elec_money" label="电费" show-overflow-tooltip />
        <el-table-column prop="orw_sevice_money" label="服务费" show-overflow-tooltip />
        <el-table-column prop="total_dec_money" label="优惠合计" show-overflow-tooltip />
        <el-table-column prop="real_money" label="已付金额" show-overflow-tooltip />
        <el-table-column prop="or_status_text" label="订单状态" show-overflow-tooltip />
      </el-table>
    </w-dialog>

    <!-- 上传附件 -->
    <w-dialog ref="upDialog" class="add-dialog" title="上传附件" width="38%" btnWidth="140px" top="20vh" confirmText="确认上传"
      @wConfirm="addConfirm">
      <div>
        <div class="msg-title">上传附件</div>
        <img-upload type="filePdf" :annexLists="annexLists" @uploadFile="uploadFile" @deleteFile="deleteFile"
          suggestText="">
        </img-upload>
      </div>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch, } from "vue";
  import { Order, } from "@/plugins/api.js";
  import { ElMessage, ElMessageBox, } from "element-plus";
  import { useStore } from "vuex";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import dayjs from "dayjs";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const qiniuData = computed(() => store.state.Config.qiniuData);
  const authData = ref([]);
  // 监听权限
  watch(() => menuTokens.value, (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
    {
      deep: true,
      immediate: true,
    }
  )
  /** 表格对象 */
  const roleTable = ref(null);
  const detailsDialog = ref(null);  // 详情对象
  const tableData = ref([]);  // 详情表格数据
  const upDialog = ref(null);  // 上传附件对象
  const annexLists = ref([]);
  const currentRow = ref({});
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "date",
      name: "start_time",
      name2: "end_time",
      value: [],
      placeholder: "请选择日期范围",
      type: "daterange",
    },
    {
      filterType: "select",
      name: "status",
      value: "",
      placeholder: "请选择开票状态",
      options: [{ id: '1', name: '未开票' }, { id: '2', name: '已开票' }],
      val: "id",
      lab: "name",
    },
    {
      name: "keywords",
      filterType: "search",
      value: "",
      placeholder: "请输入用户手机号",
    },
  ]);

  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "mn_money",
      label: "开票金额",
      color: "--text-color",
      showTooltip: true,
      minWidth: 50,
    },
    {
      type: "block",
      prop: "or_total",
      label: "订单数量",
      active: "orderQuantity",
      minWidth: 50,
      token: "n_3K1HUxZInHvUCNCnLFmlC1PKdl5g",
    },
    {
      prop: "mn_title",
      label: "发票抬头",
      color: "--text-color",
      showTooltip: true,
      minWidth: 150,
    },
    {
      prop: "tax_number",
      label: "纳税人识别号",
      color: "--text-color",
      minWidth: 150,
      showTooltip: true,
    },
    {
      prop: "mn_type_name",
      label: "类型",
      color: "--text-color",
      showTooltip: true,
      minWidth: 50,
    },
    {
      prop: "m_mobile",
      label: "用户手机",
      color: "--text-color",
      showTooltip: true,
      minWidth: 100,
    },
    {
      prop: "mn_email",
      label: "邮箱",
      color: "--text-color",
      showTooltip: true,
      minWidth: 120,
    },
    {
      prop: "mn_ctime",
      label: "申请时间",
      color: "--text-color",
      showTooltip: true,
      minWidth: 100,
    },
    {
      prop: "mn_status_text",
      label: "开票状态",
      type: "option",
      metaData: ["已开票", "未开票",],
      colorData: ["var(--theme-color)", "var(--error-color)",],
      showTooltip: true,
      minWidth: 80,
    },
    {
      type: 'customRender',
      prop: "email_process_status",
      label: "邮件发送状态",
      active: "email_process_status",
      minWidth: 140,
      showTooltip: true,
    },
    {
      type: "operation",
      prop: "",
      label: "附件",
      minWidth: 120,
      bottons: [
        {
          name: "下载",
          action: "download",
          type: "customRender",
        },
        {
          name: "上传附件",
          action: "upload",
          type: "customRender",
        },
      ],
    },
  ]);
  /**
   * 
   * 点击订单数量
   * 
   * */
  const orderQuantity = (row) => {
    Order.uploadInvoice({ mn_id: row.mn_id, req_type: 'list' }).then((res) => {
      if (res.Code === 200) {
        tableData.value = res.Data;
        detailsDialog.value.show();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 点击上传附件按钮
   * 
   * */
  const onUploadAnnexBtn = (row) => {
    annexLists.value = [];
    currentRow.value = row;
    store.dispatch("getQiniuData");
    upDialog.value.show()
  }
  /**
   * 
   * 文件上传
   * 
   * */
  const uploadFile = (data) => {
    if (annexLists.value.length > 0) {
      ElMessage.error("只能上传一个附件！");
      return false;
    }
    annexLists.value.push({ file_url: qiniuData.value.http_domain + data.key, key: data.key, name: data.filename });
  };
  /**
   * 
   * 文件删除
   * 
   * */
  const deleteFile = (data) => {
    let index1 = annexLists.value.findIndex((item) => item.key === data.key);
    annexLists.value.splice(index1, 1);
  };
  /**
   * 
   * 确认上传按钮
   * 
   * */
  const addConfirm = () => {

    let arr = [];
    if (annexLists.value.length > 0) {
      annexLists.value.forEach((item) => {
        arr = arr.concat(item.key);
      });
    } else {
      ElMessage.error("请上传附件");
      return false;
    }
    let params = {
      mn_id: currentRow.value.mn_id,
      req_type: 'upload',
      key: arr,
    };
    upDialog.value.isLoading = true;
    Order.uploadInvoice(params).then((res) => {
      upDialog.value.isLoading = false;
      if (res.Code === 200) {
        upDialog.value.close();
        roleTable.value.tableLoad();
        ElMessage.success("上传成功");
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 点击下载按钮
   * 
   * */
  const onDownloadBtn = (row) => {
    let link = document.createElement('a');
    let url = row.mn_path;
    const filename = row.m_username + dayjs().format("YYYY-MM-DD");
    // 这里是将url转成blob地址，
    fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
      link.href = URL.createObjectURL(blob);
      link.download = '';
      document.body.appendChild(link);
      link.download = filename;
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(row.path);
    })
  }
  /**
   * 
   * 重新发送
   * 
   * */
  const onResendBtn = (row) => {
    ElMessageBox.confirm(
      `确定重新发送邮件吗？`,
      '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
    }
    )
      .then(() => {
        Order.sendingEmails({ mn_id: row.mn_id }).then((res) => {
          if (res.Code === 200) {
            roleTable.value.tableLoad();
            ElMessage.success("发送成功！");
          } else {
            ElMessage.error(res.Message);
          }
        });
      })
      .catch(() => { })
  }

  onMounted(() => {
    roleTable.value.tableLoad();
  });
</script>

<style lang="scss" scoped>
  .order-prestore {
    font-family: "Source Han Sans CN";

    .el-table__footer-wrapper tbody td.el-table__cell {
      background-color: #fff;
      border-color: #fff;
    }

    .el-table__fixed::before,
    .el-table::before,
    .el-table__fixed-right::before {
      background-color: #fff;
    }

    .el-row {
      border: none;
    }

    .content {
      padding: 20px;
    }

    .add-dialog {
      .el-dialog__body {
        padding: 20px 20px 30px 30px;
      }
    }

    .el-table {
      td.el-table__cell {
        padding: 9px 0;
      }
    }

    .text-red {
      color: var(--error-color);
    }

    .email-status {
      padding: 4px 0;
      min-height: 20px;
      width: 76px;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .amount-title {
    font-size: 18px;
  }

  .order-total {
    margin-left: 100px;
    color: #1AC994;
  }
</style>